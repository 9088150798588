import { Row, Col, Button } from 'antd';
import style from './style.module.css';

const NoPackageSelected = () => {
  const register = () => {
    // TODO: implement
    // keycloak.register();
  };

  const login = () => {
    // TODO: implement
    // keycloak.login({
    //   redirectUri: window.location.origin + '/mein-vertrag'
    // });
  };

  return (
    <div className={style.no_package_container}>
      <Row className={style.content_wrapper}>
        <Col span={24}>
          <p className={style.no_package_text}>
            Herzlich willkommen im MUENET Kundenportal.
          </p>
        </Col>

        <Col span={24}>
          <p className={style.select_on_website_text}>
            Sie sind bereits Kunde? Hier können Sie sich registrieren oder
            einloggen. Bitte halten Sie für die Registrierung, Ihre Kundennummer
            und E-Mail-Adresse bereit.
          </p>
        </Col>

        <Col span={24}>
          <p
            className={style.select_on_website_text}
            style={{ marginTop: '0px' }}
          >
            (Diese Funktion wird innerhalb des ersten Quartals 2025 verfügbar
            sein.)
          </p>
        </Col>

        <Col xs={24} className={style.auth_buttons_wrapper}>
          {/* TODO: change className style from disabled_auth_button to go_to_website_button when V2 is ready */}
          <Button
            className={style.disabled_auth_button}
            onClick={register}
            disabled
          >
            Zur Registrierung
          </Button>
          <Button
            className={style.disabled_auth_button}
            onClick={login}
            disabled
          >
            Zum Log-in
          </Button>
        </Col>

        <Col span={24}>
          <p className={style.select_on_website_text}>
            Sie sind noch kein Kunde und interessieren sich für einen
            Glasfaseranschluss von uns? Besuchen Sie unsere Webseite. Nach einer
            Verfügbarkeitsprüfung für Ihre Adresse können Sie sofort online
            buchen.
          </p>
        </Col>

        <Col xs={24} xl={6}>
          <Button className={style.go_to_website_button}>
            <a href={'https://www.muenet-glasfaser.de/'}>Zur Webseite</a>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NoPackageSelected;
