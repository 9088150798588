import { useEffect } from 'react';
import { Shortcut } from './shortcut';

type CallbackFunction = () => void;

const useShortcutListener = (
  shortcut: Shortcut,
  callback: CallbackFunction
) => {
  useEffect(() => {
    const keydownCallback = (event: KeyboardEvent) => {
      if (resolveShortcut(shortcut, event)) {
        event.preventDefault();

        callback();
      }
    };
    document.addEventListener('keydown', keydownCallback);

    return () => {
      document.removeEventListener('keydown', keydownCallback);
    };
  }, []);
};

const isMacOS = (): boolean => {
  return navigator.userAgent.toLowerCase().includes('mac');
};

const resolveShortcut = (shortcut: Shortcut, event: KeyboardEvent): boolean => {
  const isMac = isMacOS();
  const commandKey = isMac ? event.metaKey : event.ctrlKey;

  switch (shortcut) {
    case Shortcut.COMMAND_SHIFT_S:
      return commandKey && event.shiftKey && event.code === `KeyS`;
    case Shortcut.COMMAND_SHIFT_B:
      return commandKey && event.shiftKey && event.code === `KeyB`;
    default:
      return false;
  }
};

export default useShortcutListener;
